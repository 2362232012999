import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { PREBUILT_THEMES } from './PREBUILT_THEMES';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ThemeConfig } from './theme-config';
import { ColorConfig } from './color-config';
import * as tinycolor from 'tinycolor2';


@Injectable({
  providedIn: 'root'
})
export class ThemeService implements OnDestroy {

  private static readonly THEME_VARIABLE_PREFIX = '--theme';
  private prebuiltThemes: any[] = PREBUILT_THEMES;

  private requiredColorShades: string[] = [
    '--theme-primary-color-500',
    '--theme-primary-color-50',
    '--theme-primary-color-700',
    '--theme-primary-color-contrast-500',
    '--theme-primary-color-contrast-100',
    '--theme-primary-color-contrast-700',
    '--theme-accent-color-500',
    '--theme-accent-color-50',
    '--theme-accent-color-700',
    '--theme-accent-color-contrast-500',
    '--theme-accent-color-contrast-100',
    '--theme-accent-color-contrast-700',
    '--theme-custom-background-color-500',
    '--theme-custom-background-color-50',
    '--theme-custom-background-color-700',
    '--theme-custom-background-color-contrast-500',
    '--theme-custom-background-color-contrast-100',
    '--theme-custom-background-color-contrast-700',
    '--theme-highlighter-color-500',
    '--theme-highlighter-color-50',
    '--theme-highlighter-color-700',
    '--theme-highlighter-color-contrast-500',
    '--theme-highlighter-color-contrast-100',
    '--theme-highlighter-color-contrast-700',
  ];

  // DEFAULT_THEME = environment.fallbackTheme;
  DEFAULT_THEME = this.prebuiltThemes[0];

  // Allows listening to theme changes
  currentTheme: BehaviorSubject<any> = new BehaviorSubject(this.DEFAULT_THEME);

  private readonly renderer: Renderer2;
  private readonly darkThemeClass: string = 'theme-variables-dark';
  private readonly lightThemeClass: string = 'theme-variables';
  private isDarkThemeEnabled: boolean = false;

  constructor(
    private rendererFactory: RendererFactory2
  ) {
    // Create new renderer from renderFactory, to make it possible to use renderer2 in a service
    this.renderer = this.rendererFactory.createRenderer(null, null);
    
  }

  ngOnDestroy(): void {
    this.renderer?.destroy();
  }

  initializeTheme() {
    this._subscribeToThemeChanges();
  }

  getCurrentThemeInfo() {
    return this.currentTheme;
  }

  toggleDarkTheme(isDarkEnabled: any) {
    if (isDarkEnabled) {
      this.renderer.removeClass(document.body, this.lightThemeClass);
      this.renderer.addClass(document.body, this.darkThemeClass);
    }
    else {
      this.renderer.removeClass(document.body, this.darkThemeClass);
      this.renderer.addClass(document.body, this.lightThemeClass);
    }

    // setting local storage for handle the dark mode toggle in preference
    localStorage.setItem("darkMode", isDarkEnabled.toString());
    this.isDarkThemeEnabled = isDarkEnabled;
  }

  private _detectPrefersColorScheme() {
    
    // getting local storage data for default dark mode
    let data = localStorage.getItem("darkModePreference");
      //  Dark Mode Toggle click ON
    if (data === "true" || data === null) {
      this.toggleDarkTheme(true);
    }
      //Dark Mode Toggle click OFF
    else if (data === "false") {
      this.toggleDarkTheme(false);
    }
    else {
      if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
        // Set colorScheme to Dark if prefers-color-scheme is dark. Otherwise, set it to Light.
        this.isDarkThemeEnabled = window.matchMedia('(prefers-color-scheme: dark)').matches ? true : false;
        this.toggleDarkTheme(this.isDarkThemeEnabled);
      } else {
        // If the browser does not support prefers-color-scheme, set the default to dark.
        this.isDarkThemeEnabled = false;
        this.toggleDarkTheme(this.isDarkThemeEnabled);
      }
    }
 
  }

  private _subscribeToThemeChanges(): void {
    this.currentTheme.subscribe((themeInfo) => {
      const themeConfig: ThemeConfig = {
        primaryColor: themeInfo.primaryColor,
        accentColor: themeInfo.accentColor
      }
      this._setupMainPalettes(themeConfig);
      this._detectPrefersColorScheme();
    });
  }

  /**
   * This method will generate the theme palette required by Angular Material
   * It will correctly set up the variable names used in /assets/styles/_variables.scss
   * @param themeConfig
   * @private
   */
  private _setupMainPalettes(themeConfig: any): void {
    Object.keys(themeConfig).forEach((key: string) => {
      const selectedColorValue: string = themeConfig[key];
      // Should be for example: --theme-primary or --theme-accent etc..
      const variableName: string = this._prependVariableName(this._convertCamelCaseToKebabCase(key));
      // Generate the palette colors
      const colorPalette: Array<ColorConfig> = this._generateColorPalette(selectedColorValue);

      colorPalette.forEach((colorConfig: ColorConfig) => {
        // Destructure the color config
        const { colorVariant, colorHexValue, shouldHaveDarkContrast } = colorConfig;

        // Set the color variable
        const colorVariableName = `${variableName}-${colorVariant}`;
        this._setColorVariable(colorVariableName, colorHexValue);

        // By Angular material, contrasted colors are either white, or a darker color
        // Set the contrast color
        const contrastedColorVariableName = `${variableName}-contrast-${colorVariant}`;
        const contrastedColorValue = shouldHaveDarkContrast ? 'rgba(0, 0, 0, 0.87)' : '#fff';
        this._setColorVariable(contrastedColorVariableName, contrastedColorValue);
      });
    });
  }

  /**
  * This method generates a color palette comprised of 14 main and 14 contrast colors per the Angular material specification
  * It will allow us to have different shades of some color and we can use all of those shades in our material and non-material
  * components via css.
  * The configuration can never be 100% accurate to the Material stock colors, as they are sometimes hand-made by a designer
  * So this calculation will never be 100% accurate to the original colors provided in the Material design CSS files
  * @param hexColor
  * @private
  */
  private _generateColorPalette(hexColor: string): Array<ColorConfig> {
    const baseLight = tinycolor('#ffffff');
    const baseDark = this._multiply(tinycolor(hexColor).toRgb(), tinycolor(hexColor).toRgb());
    const baseTriad = tinycolor(hexColor).tetrad();

    return [
      this._mapColorConfig(tinycolor.mix(baseLight, hexColor, 30), '100'),
      this._mapColorConfig(tinycolor.mix(baseLight, hexColor, 50), '200'),
      this._mapColorConfig(tinycolor.mix(baseLight, hexColor, 70), '300'),
      this._mapColorConfig(tinycolor.mix(baseLight, hexColor, 85), '400'),
      this._mapColorConfig(tinycolor.mix(baseLight, hexColor, 12), '50'),
      this._mapColorConfig(tinycolor.mix(baseLight, hexColor, 100), '500'),
      this._mapColorConfig(tinycolor.mix(baseDark, hexColor, 87), '600'),
      this._mapColorConfig(tinycolor.mix(baseDark, hexColor, 70), '700'),
      this._mapColorConfig(tinycolor.mix(baseDark, hexColor, 54), '800'),
      this._mapColorConfig(tinycolor.mix(baseDark, hexColor, 25), '900'),
      this._mapColorConfig(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(80).lighten(65), 'A100'),
      this._mapColorConfig(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(80).lighten(55), 'A200'),
      this._mapColorConfig(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(100).lighten(45), 'A400'),
      this._mapColorConfig(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(100).lighten(40), 'A700')
    ];
  }

  /**
   * Map the color and its variant to something that we understand
   * Also check if we need to use a light or dark contrast color
   * @param tinyColorInstance
   * @param colorVariant
   * @private
   */
  private _mapColorConfig(tinyColorInstance: tinycolor.Instance, colorVariant: string): ColorConfig {
    return {
      colorVariant,
      colorHexValue: tinyColorInstance.toHexString(),
      shouldHaveDarkContrast: tinyColorInstance.isLight()
    };
  }

  private _multiply(rgb1: tinycolor.ColorFormats.RGB, rgb2: tinycolor.ColorFormats.RGB): tinycolor.Instance {
    rgb1.r = Math.floor((rgb1.r * rgb2.r) / 255);
    rgb1.g = Math.floor((rgb1.g * rgb2.g) / 255);
    rgb1.b = Math.floor((rgb1.b * rgb2.b) / 255);
    const { r, g, b } = rgb1;

    return tinycolor(`rgb ${r} ${g} ${b}`);
  }



  private _prependVariableName(key: string): string {
    return `${ThemeService.THEME_VARIABLE_PREFIX}-${key}`;
  }

  /**
   * Change a camelCase variable to a kebab case
   * e.g: primaryColor -> primary-color
   * @param key
   * @private
   */
  private _convertCamelCaseToKebabCase(key: string): string {
    return key.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
  }

  private _setColorVariable(variable: string, color: string): void {
    if (this.requiredColorShades.includes(variable)) {
      document.documentElement.style.setProperty(variable, color);
    }
  }
}



