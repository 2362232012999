import { Type } from "@angular/core";
import { Profile } from "@dynamic-mapper/mapper";
import { SampleProfile } from "./sample/sample.mappings";
import { UserProfile } from "./user/user.mappings";
import { AppointmentProfile } from "./customer/appointment.mapping";
import { ServiceNameData } from "./service/service-name.mapping";
import { ServicePageListData } from "./service/service-page-list.mapping";
import { CustomerProfile } from "./customer/customer.mappings";
import { DailyScheduleData } from "./settings/daily-schedule.mapping";
import { HistoryData } from "./book-appointment/history.mapping";
import { EventProfile } from "./calendar/event.mapping";
import { OpenHours } from "./calendar/open-hours.mapping";
import { NotesData } from "./book-appointment/notes.mapping";
import { CustomerDetail } from "./customer/customer-details.dto";

export const MAPPING_PROFILES: Type<Profile>[] = [
    SampleProfile,
    UserProfile,
    CustomerProfile,
    AppointmentProfile,
    ServiceNameData,
    ServicePageListData,
    DailyScheduleData,
    HistoryData,
    NotesData,
    AppointmentProfile,
    EventProfile,
    OpenHours,
    CustomerDetail
]
