import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {

  constructor() { }

  getApiUrl(path: string): string {
    let apiUrl: string;
    apiUrl = environment.apiScheme + '://' + this.sanitizeBase(environment.apiBaseUrl) + this.sanitizePath(path);
    return apiUrl;
  }

  getSubApiUrl(path: string): string {
    let apiUrl: string;
    apiUrl = environment.apiScheme + '://' + this.sanitizeBase(environment.subApiBaseUrl) + this.sanitizePath(path);
    return apiUrl;
  }

  private sanitizeBase(baseUrl: string): string {
    if (baseUrl.endsWith('/')) {
      return baseUrl;
    }
    else {
      return baseUrl + '/';
    }
  }

  private sanitizePath(path: string): string {
    if (path.startsWith('/')) {
      return path.substring(1);
    }
    else {
      return path;
    }
  }

}
