import { Injectable, Type } from '@angular/core';
import { Mapper } from '@dynamic-mapper/angular';
import { MappingPair } from '@dynamic-mapper/mapper';
import { ThemeService } from '../../theme/theme.service';

@Injectable({
  providedIn: 'root'
})
export class CommonHelperService {

  constructor(
    private readonly mapper: Mapper, private themeservice: ThemeService
  ) { }

  //Generic Array Mapper Method
  genericArrayMapper<I,O>(data: I[], mapProfile: MappingPair<I,O>): O[] {
    const mappingProfile: MappingPair<I,O> =  mapProfile;
    let arrayData: O[]= [];
    if (data && data.length > 0) {
      data.forEach((d: I) => {
        arrayData.push(this.mapper.map(mappingProfile,d));
      });
    }
    return arrayData;
  }

  //Generic Mapper Method
  genericObjectMapper<I,O>(data: I, mapProfile: MappingPair<I,O>): O {

    const mappingProfile: MappingPair<I,O> =  mapProfile;
    let mappedData!: O;
    if (data) {
       mappedData = this.mapper.map(mappingProfile,data);
    }
    return mappedData;

  }


  isDarkEnabled(): boolean {
    // get the local storage value to handle the dark and light mode image
    let data = localStorage.getItem("darkModePreference");
    
    if (data === null || data === 'true') {
      this.themeservice.toggleDarkTheme(true);
      return true;
    } else if (data === 'false') {
      this.themeservice.toggleDarkTheme(false);
      return false;
    } else {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
        let darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        return darkMode;
      } else {
        return false;
      }
    }
  }
  


}
