import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../../utilities/http-helper/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(private http: HttpClient, private httpHelper: HttpHelperService) { }


  getRoleList(paramsReq): Observable<any> {
    let params = new HttpParams()
      .set('ClientId', paramsReq.ClientId)
      .set('UserTypeId', paramsReq.UserTypeId)
      .set('search', paramsReq.search)

    const apiUrl = this.httpHelper.getApiUrl(`/api/UserRoles/GetUserRolesName`);
    return this.http.get<any>(apiUrl, { params: params })
  }

  getStaffPaginationList(paramsReq): Observable<any> {
    let params = new HttpParams()
      .set('PageNumber', paramsReq.PageNumber)
      .set('PageSize', paramsReq.PageSize)
      .set('FilterId', paramsReq.FilterId)
      .set('ClientsId', paramsReq.ClientsId)
      .set('SearchTerm', paramsReq.SearchTerm)

    const apiUrl = this.httpHelper.getApiUrl(`/api/Users/GetUsersListWithPagination`);
    return this.http.get<any>(apiUrl, { params: params })
  }

  resendEmail(data): Observable<any> {
    const apiUrl = this.httpHelper.getApiUrl(`/api/Users/ReSendEmailStaffRegistration`);
    return this.http.post<any>(apiUrl, data)
  }

  createStaff(data): Observable<any> {
    const requestOptions: Object = {
      responseType: 'text'
    }
    const apiUrl = this.httpHelper.getApiUrl(`/api/Users/CreateUser`);
    return this.http.post<any>(apiUrl, data, requestOptions)
  }

  updateStaff(data): Observable<any> {
    const apiUrl = this.httpHelper.getApiUrl(`/api/Users/UpdateUser`);
    return this.http.put<any>(apiUrl, data)
  }

  getRolePaginationList(paramsReq): Observable<any> {
    let params = new HttpParams()
      .set('PageNumber', paramsReq.PageNumber)
      .set('PageSize', paramsReq.PageSize)
      .set('FilterId', paramsReq.FilterId)
      .set('ClientsId', paramsReq.ClientsId)
      .set('SearchTerm', paramsReq.SearchTerm)

    const apiUrl = this.httpHelper.getApiUrl(`/api/UserRoles/GetUserRolesListWithPagination`);
    return this.http.get<any>(apiUrl, { params: params })
  }

  getRoleAccess(id): Observable<any> {
    let params = new HttpParams()
    .set('UserRoleId',id)
    const apiUrl = this.httpHelper.getApiUrl(`/api/UserRoles/GetUserRoleAccess`);
    return this.http.get<any>(apiUrl, { params: params })
  }

  getAccessScreen(id): Observable<any> {
    let params = new HttpParams()
    .set('UserTypeId',id)
    const apiUrl = this.httpHelper.getApiUrl(`/api/UserRoles/GetAccessScreens`);
    return this.http.get<any>(apiUrl, { params: params })
  }

  createRole(data):Observable<any>{ 
    const apiUrl = this.httpHelper.getApiUrl(`/api/UserRoles/CreateUserRole`);
    return this.http.post<any>(apiUrl,data)
  }

  updateRole(data):Observable<any>{ 
    const requestOptions: Object = {
      responseType: 'text'
    }
    const apiUrl = this.httpHelper.getApiUrl(`/api/UserRoles/UpdateUserRole`);
    return this.http.put<any>(apiUrl,data,requestOptions)
  }
}
