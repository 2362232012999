/**
 * This service file used to connect the profile changes while update profile image its update on top icon also.
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private profileImageUpdate = new Subject<void>()

  updateProfile() {
    this.profileImageUpdate.next();
  }

  getUpdateObservable() {
    return this.profileImageUpdate.asObservable();
  }
  
}
