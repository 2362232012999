import { Component, Inject, OnInit } from '@angular/core';
import { AuthFacadeService } from 'src/app/facades/auth/auth-facade.service';
import { Router } from '@angular/router';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';
import { Permission } from 'src/app/core/store/permission/permission';
import { OnboardingFacadeService } from 'src/app/facades/onboarding/onboarding-facade.service';
import { UserFeedbackState } from 'src/app/core/utilities/user-feedback-state/user-feedback-state';
import { ConfirmOnboardService } from 'src/app/core/services/confirm-onboard/confirm-onboard.service';
import { StaffFacadesService } from 'src/app/facades/staff/staff-facades.service';
import { FeedbackSnackbarService } from "src/app/core/utilities/feedback-snackbar/feedback-snackbar.service";
// import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  value: any;
  stylistId: any;
  userFeedback = new UserFeedbackState();
  clientId: any;
  roleData: any;
  locationId: any;
  url: string;
  cliId: string;
  busiName: string;

  constructor(private authFacadeService: AuthFacadeService,
    private router: Router,
    private conOnboard: ConfirmOnboardService,
    private onboardFacade: OnboardingFacadeService,
    private staffFacadeService: StaffFacadesService,
    private bottomSheet: MatBottomSheetRef<LogoutComponent>,
    private snackBar: FeedbackSnackbarService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private permission: Permission
  ) {
    this.cliId = localStorage.getItem('clientId');
    this.busiName = localStorage.getItem('businessName');
    this.url = environment.onboardingUrl+"?id="+this.cliId+"&name="+this.busiName;
    this.value = data?.value;
    this.stylistId = data?.id;
    this.clientId = data?.cliId;
    this.roleData = !data;
    this.locationId = data?.locId;
  }
  /**
   * for dialog popup
   */
  // @Inject(MAT_DIALOG_DATA) data: { message: string },
  // public dialogRef: MatDialogRef<LogoutComponent>
  ngOnInit(): void {
  }

  // logout
  logout() {
    this.authFacadeService.signOut().then((res: any) => {
      localStorage.clear();
      this.permission.clearPermission();
      const domain = environment.domain;
      const pastDate = new Date(0).toUTCString();
      document.cookie = `myObjectCookie=; domain=${domain}; path=/; expires=${pastDate}`;
      this.router.navigate(['auth/login']);
    });
    this.bottomSheet.dismiss();
  }

  close() {
    this.bottomSheet.dismiss();
  }

  singleApprove() {
    this.userFeedback.startLoading();
    let data = {
      "clientId": 0,
      "stylistId": this.stylistId,
      "locationId": 0,
      "registerStatus": true,
      "recordStatus": true
    }
    this.onboardFacade.stylistApproveOrReject(data).subscribe((res: any) => {
      this.conOnboard.updateOnboard();
      this.userFeedback.stopLoading();
      this.bottomSheet.dismiss();
    })
  }

  singleReject() {
    this.userFeedback.startLoading();
    let data = {
      "clientId": 0,
      "locationId": 0,
      "stylistId": this.stylistId,
      "registerStatus": false,
      "recordStatus": false
    }
    this.onboardFacade.stylistApproveOrReject(data).subscribe((res: any) => {
      this.conOnboard.updateOnboard();
      this.userFeedback.stopLoading();
      this.bottomSheet.dismiss();
    })
  }

  approveAll() {
    this.userFeedback.startLoading();
    let data = {
      "clientId": this.clientId ? this.clientId : 0,
      "locationId": this.locationId ? this.locationId : 0,
      "stylistId": 0,
      "registerStatus": true,
      "recordStatus": true
    }
    this.onboardFacade.stylistApproveOrReject(data).subscribe((res: any) => {
      this.conOnboard.updateOnboard();
      this.userFeedback.stopLoading();
      this.bottomSheet.dismiss();
    })
  }

  rejectAll() {
    this.userFeedback.startLoading();
    let data = {
      "clientId": this.clientId ? this.clientId : 0,
      "locationId": this.locationId ? this.locationId : 0,
      "stylistId": 0,
      "registerStatus": false,
      "recordStatus": false
    }
    this.onboardFacade.stylistApproveOrReject(data).subscribe((res: any) => {
      this.conOnboard.updateOnboard();
      this.userFeedback.stopLoading();
      this.bottomSheet.dismiss();
    })
  }

  updateRole() {
    this.userFeedback.startLoading();
    let data = {
      "userRoleId": this.roleData.userRoleId,
      "userTypeId": 2,
      "roleName": this.roleData.roleName,
      "clientId": this.roleData.clientId == '' ? 0 : this.roleData.clientId,
      "recordStatus": this.roleData.recordStatus,
      "loggedUserId": this.roleData.loggedUserId,
      "access": this.roleData.access
    }
    this.staffFacadeService.updateRole(data).subscribe({
      next: (res) => {
        this.userFeedback.stopLoading();
        this.router.navigate(['/settings/staff-role']);
        this.bottomSheet.dismiss();
      }, error(err) {

      },
    })
  }

  copyUrl() {
    navigator.clipboard.writeText(this.url).then(() => {
      this.snackBar.successSnackbar("Stylist onboarding link copied to clipboard!");
    }, (err) => {
      console.error('Failed to copy: ', err);
    });
  }
}
