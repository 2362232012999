import { Injectable } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { Observable } from 'rxjs';
import { User } from '../../models/user/user';
import { UserDTO } from 'src/app/domain/user/user.dto';
import { CommonHelperService } from '../../utilities/common-helper/common-helper.service';
import { UserProfile } from '../../mappings/user/user.mappings';
import { UserInput } from '../../models/user/user-input';
import { PreferenceInput } from '../../models/user/preference-input';
import { Permission } from '../../store/permission/permission';
import { StaffService } from '../../services/staff/staff.service';

@Injectable({
  providedIn: 'root'
})
export class UserBusinessService {

  constructor(
    private userService: UserService,
    private commonHelper: CommonHelperService,
    private permission: Permission,
    private staffService: StaffService
  ) { }

  getStylist(id: number): Observable<User> {
    return new Observable((observer) => {
      this.userService.getStylist(id).subscribe({
        next: (response: User[]) => {
          try {
            if (response.length != 0) {
              observer.next(response[0])
            } else {
              observer.error('Invalid URL');
            }
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getUser(id: number): Observable<User> {
    return new Observable((observer) => {
      this.userService.getUser(id).subscribe({
        next: (response: User) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getCustomer(id: number): Observable<User> {
    return new Observable((observer) => {
      this.userService.getCustomer(id).subscribe({
        next: (response: User) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getClient(id: number): Observable<User> {
    return new Observable((observer) => {
      this.userService.getClient(id).subscribe({
        next: (response: User[]) => {
          try {
            if (response.length != 0) {
              observer.next(response[0])
            } else {
              observer.error('Invalid URL');
            }
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  updateUser(data: UserInput): Observable<UserInput> {
    return new Observable((observer) => {
      this.userService.updateUser(data).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  updateClient(data: UserInput): Observable<UserInput> {
    return new Observable((observer) => {
      this.userService.updateClient(data).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getUserByAuth(paramsReq: object) {
    return new Observable((observer) => {
      this.userService.getUserByAuth(paramsReq).subscribe({
        next: (response) => {
          observer.next(response);
        },
        error: ((error) => {
          observer.error(error);
        })
      });
    })
  }

  verifyEmailFirebase(authId: string): Observable<string> {
    return new Observable((observer) => {
      this.userService.verifyEmailFirebase(authId).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  userDetailsByAuth(authId: string): Observable<string> {
    return new Observable((observer) => {
      this.userService.userDetailsByAuth(authId).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  /**
   * This API function is used to update the preference toggle value.
   * @param data object
   * @returns 
   */
  getPreference(data: PreferenceInput) {
    return new Observable((observer) => {
      this.userService.getPreference(data).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getCustomerName(paramsReq: object): Observable<any> {
    return new Observable((observer) => {
      this.userService.getCustomerName(paramsReq).subscribe({
        next: (response) => {
          observer.next(response);
        },
        error: ((error) => {
          observer.error(error);
        })
      });
    })
  }

  setMenuByRoleAccess(): Observable<any> {
    const hasPermissionData = this.permission.hasPermissions();
    return new Observable((observer) => {
      if (hasPermissionData) {
        const effectivePermissions = this.permission.getPermissions();
        observer.next(effectivePermissions);
        observer.complete();
      } else {
        let id = localStorage.getItem('roleId');
        this.staffService.getRoleAccess(id).subscribe({
          next: (response) => {
            try {
              if (response) {
                this.permission.setPermissions(response);
                observer.next(response);
                observer.complete();
              }
            }
            catch (e) {
              observer.error(e);
            }
          },
          error: (error) => {
            observer.error(error);
            observer.complete();
          }
        })
      }
    })
  }
}



