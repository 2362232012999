import { Profile, MappingPair } from '@dynamic-mapper/mapper';
import { History } from '../../models/book-appointment/history.model'; 
import { HistoryDTO } from 'src/app/domain/book-appointment/history.dto'; 

export class HistoryData extends Profile {
    static readonly DtoToDomain = new MappingPair<History, HistoryDTO>();

    constructor() {
        super();

        this.createMap(HistoryData.DtoToDomain, {
            customerFirstName: opt => opt.mapFrom(src => src.customerFirstName),
            customerLastName: opt => opt.mapFrom(src => src.customerLastName),
            appointmentId: opt => opt.mapFrom(src => src.appointmentId),
            date: opt => opt.mapFrom(src => src.date),
            startTime: opt => opt.mapFrom(src => src.startTime),
            isPast: opt => opt.mapFrom(src => src.isPast),
            recordstatus: opt => opt.mapFrom(src => src.recordstatus),
            isApproved: opt => opt.mapFrom(src => src.isApproved),
            stylistId: opt => opt.mapFrom(src => src.stylistId),
            stylist: opt => opt.mapFrom(src => src.stylist)
        });
    }
}