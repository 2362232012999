import { Profile, MappingPair } from '@dynamic-mapper/mapper';
import { NotesDTO } from 'src/app/domain/book-appointment/notes.dto';
import { Notes } from '../../models/book-appointment/notes.modal';

export class NotesData extends Profile {
    static readonly DtoToDomain = new MappingPair<Notes, NotesDTO>();

    constructor() {
        super();

        this.createMap(NotesData.DtoToDomain, {
            createdFirstName: opt => opt.mapFrom(src => src.createdLastName),
            createdLastName: opt => opt.mapFrom(src => src.createdLastName),
            notess: opt => opt.mapFrom(src => src.notess)
        });
    }
}