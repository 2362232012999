import { MappingPair, Profile } from "@dynamic-mapper/mapper";
import { User } from "../../models/user/user";
import { UserDTO } from "src/app/domain/user/user.dto";

export class UserProfile extends Profile {
  static readonly DtoToDomain = new MappingPair<User,UserDTO>();

  constructor() {
    super();

    this.createMap(UserProfile.DtoToDomain, {
      id: opt => opt.mapFrom(src => src.id),
      userId: opt => opt.mapFrom(src => src.userId),
      firstName: opt => opt.mapFrom(src => src.firstName),
      lastName: opt => opt.mapFrom(src => src.lastName),
      email: opt => opt.mapFrom(src => src.email),
      authId: opt => opt.mapFrom(src => src.authId),
      signInMethod: opt => opt.mapFrom(src => src.signInMethod),
      // updatedBy: opt => opt.mapFrom(src => src.updatedBy)
    })
  }
}
