import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaffBusinessService } from 'src/app/core/business/staff/staff-business.service';
import { Permission } from 'src/app/core/store/permission/permission';

@Injectable({
  providedIn: 'root'
})
export class StaffFacadesService {

  constructor(private staffBusiness: StaffBusinessService, private permission: Permission) { }

  getRoleList(paramsReq): Observable<any> {
    return this.staffBusiness.getRoleList(paramsReq);
  }

  getStaffPaginationList(paramsReq): Observable<any> {
    return this.staffBusiness.getStaffPaginationList(paramsReq);
  }

  resendEmail(data): Observable<any> {
    return this.staffBusiness.resendEmail(data);
  }

  createStaff(data): Observable<any> {
    return this.staffBusiness.createStaff(data);
  }

  updateStaff(data): Observable<any> {
    return this.staffBusiness.updateStaff(data);
  }

  getRolePaginationList(paramsReq): Observable<any> {
    return this.staffBusiness.getRolePaginationList(paramsReq);
  }

  getRoleAccess(id): Observable<any> {
    return this.staffBusiness.getRoleAccess(id);
  }

  getAccessScreen(id): Observable<any> {
    return this.staffBusiness.getAccessScreen(id);
  }

  createRole(data):Observable<any>{ 
    return this.staffBusiness.createRole(data);
  }

  updateRole(data):Observable<any>{ 
    return this.staffBusiness.updateRole(data);
  }
}
