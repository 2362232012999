import { Profile, MappingPair } from '@dynamic-mapper/mapper';
import { DailySchedule } from '../../models/settings/daily-schedule.model'
import { DailyScheduleDTO } from 'src/app/domain/settings/daily-schedule.dto';

export class DailyScheduleData extends Profile {
    static readonly DtoToDomain = new MappingPair<DailySchedule, DailyScheduleDTO>();

    constructor() {
        super();

        this.createMap(DailyScheduleData.DtoToDomain, {
            endDate: opt => opt.mapFrom(src => src.endDate),
            dayId: opt => opt.mapFrom(src => src.dayId),
            time: opt => opt.mapFrom(src => src.time)
        });
    }
}