export class UserFeedbackState {

    private _loading: boolean = false;
    private _errorMessage: string = '';
    private _successMessage: string = '';
    private _unauthorized: boolean = false;
    private _noData: boolean = false;

    get loading(): boolean {
        return this._loading;
    }

    get ready(): boolean {
        return !this._loading;
    }

    get errorMessage(): string {
        return this._errorMessage;
    }

    get successMessage(): string {
        return this._successMessage;
    }

    get hasError(): boolean {
        return this.errorMessage?.length > 0;
    }

    get hasSuccess(): boolean {
        return this.successMessage?.length > 0;
    }

    get hasMessage(): boolean {
        return this.hasError || this.hasSuccess;
    }

    get unauthorized(): boolean {
        return this._unauthorized;
    }

    get noData(): boolean {
        return this._noData;
    }

    startLoading(): void {
        this.reset();
        this._loading = true;
    }

    stopLoading(): void {
        this._loading = false;
    }

    setSuccess(message: string): void {
        this._loading = false;
        this._successMessage = message;
    }

    setError(message: string): void {
        this._loading = false;
        this._errorMessage = message;
    }

    setUnauthorized(message: string): void {
        this._loading = false;
        this._unauthorized = true;
    }

    setNoData(): void {
        this._loading = false;
        this._noData = true;
    }

    reset(): void {
        this._loading = false;
        this._errorMessage = '';
        this._successMessage = '';
        this._unauthorized = false;
        this._noData = false;
    }
}