import { Profile, MappingPair } from '@dynamic-mapper/mapper';
import { ServiceName } from '../../models/service/service-name.model';
import { ServiceNameDTO } from 'src/app/domain/service/service-name.dto';

export class ServiceNameData extends Profile {
    static readonly DtoToDomain = new MappingPair<ServiceName,ServiceNameDTO>();

    constructor() {
        super();

        this.createMap(ServiceNameData.DtoToDomain, {
            id: opt => opt.mapFrom(src => src.id),
            serviceId: opt => opt.mapFrom(src => src.serviceId),
            images: opt => opt.mapFrom(src => src.images),
            serviceName: opt => opt.mapFrom(src => src.serviceName),
            categoryName: opt => opt.mapFrom(src => src.categoryName),
            duration: opt => opt.mapFrom(src => src.duration),
            price: opt => opt.mapFrom(src => src.price),
        });
    }
}