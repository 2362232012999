import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainLayoutComponent } from "./view/main-layout-container/main-layout/main-layout.component";
import { AuthGuard } from "./core/guards/auth-guard/auth.guard";
import { PrivacyComponent } from "./view/settings-container/privacy/privacy.component";
import { PermissionGuard } from "./core/guards/permission-guard/permission.guard";

const typeId=Number(localStorage.getItem('typeId'));

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivateChild: [AuthGuard,PermissionGuard],
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      // {
      //   path: "",
      //   redirectTo: typeId==4?"/locations":"/partners",
      //   pathMatch: "full",
      // },
      {
        path: "dashboard",
        data: { pageTitle: "Dashboard" },
        loadChildren: () =>
          import(
            "src/app/view/dashboard-container/dashboard-container.module"
          ).then((m) => m.DashboardContainerModule),
      },
      {
        path: "partners",
        data: { pageTitle: "Partner Management" },
        loadChildren: () =>
          import(
            "src/app/view/partner-container/partner-container.module"
          ).then((m) => m.PartnerContainerModule),
      },
      {
        path: "locations",
        data: { pageTitle: "Location Management" },
        loadChildren: () =>
          import(
            "src/app/view/location-container/location-container.module"
          ).then((m) => m.LocationContainerModule),
      },
      {
        path: "onboarding",
        data: { pageTitle: "Onboarding Approval" },
        loadChildren: () =>
          import(
            "src/app/view/onboarding-container/onboarding-container.module"
          ).then((m) => m.OnboardingContainerModule),
      },
      {
        path: "appointments",
        data: { pageTitle: "Appointment Management" },
        loadChildren: () =>
          import(
            "src/app/view/appointments-container/appointments-container.module"
          ).then((m) => m.AppointmentsContainerModule),
      },
      {
        path: "stylists",
        data: { pageTitle: "Stylist Management" },
        loadChildren: () =>
          import(
            "src/app/view/stylist-container/stylist-container.module"
          ).then((m) => m.StylistContainerModule),
      },
      {
        path: "services",
        data: { pageTitle: "Service Management" },
        loadChildren: () =>
          import(
            "src/app/view/services-container/services-container.module"
          ).then((m) => m.ServicesContainerModule),
      },
      {
        path: "customers",
        data: { pageTitle: "Customer Management" },
        loadChildren: () =>
          import(
            "src/app/view/customers-container/customers-container.module"
          ).then((m) => m.CustomersContainerModule),
      },
      {
        path: "settings",
        data: { pageTitle: "Settings" },
        loadChildren: () =>
          import(
            "src/app/view/settings-container/settings-container.module"
          ).then((m) => m.SettingsContainerModule),
      },
      {
        path: "subscription",
        data: { pageTitle: "Subscription Management" },
        loadChildren: () =>
          import(
            "src/app/view/subscription-container/subscription-container.module"
          ).then((m) => m.SubscriptionContainerModule),
      },
      {
        path: "partner-subscription",
        //data: { pageTitle: "Subscription Management" },
        loadChildren: () =>
        import(
          "src/app/view/partner-subscription-container/partner-subscription-container.module"
        ).then((m) => m.PartnerSubscriptionContainerModule),
      }
    ],
  },
  {
    path: "privacy",
    component: PrivacyComponent,
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./view/auth-container/auth-container.module").then(
        (m) => m.AuthContainerModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
