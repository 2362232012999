import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHelperService } from '../../utilities/http-helper/http-helper.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(private http: HttpClient, private httpHelper: HttpHelperService) { }

  getOnboardPagination(paramsReq: any): Observable<any> {
    let params = new HttpParams()
    .set('PageNumber', paramsReq.PageNumber)
    .set('PageSize', paramsReq.PageSize)
    .set('FilterId', paramsReq.FilterId)
    .set('ClientsId', paramsReq.ClientsId)
      if (paramsReq.SearchTerm) {
        params = params.append('SearchTerm', paramsReq.SearchTerm);
      }
    const apiUrl = this.httpHelper.getApiUrl('/api/Stylists/GetOnBoardingStylistsListWithPagination');
    return this.http.get(apiUrl, {params: params});
  }

  stylistApproveOrReject(data): Observable<any> {
    const apiUrl = this.httpHelper.getApiUrl('/api/Stylists/UpdateApproveRejectStylist');
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.put(apiUrl, data, requestOptions); 
  }
}
