<div class="container">
  <header class="head">
    <div class="top-header">
      <div class="logo-menu">
        <!-- <img src="/assets/images/Mvm2Fav.png" alt=""> -->
        <!-- <h3 class="bread-crumb" (click)="breadcrumbClick(pageTitle)">{{pageTitle}}</h3> -->
        <img class="logo" src="/assets/images/Mvm2Fav.png" alt="">
        <p class="logo-title">MYVISITMAKER</p>
        <!-- <span class="menu-icon">
          <mat-icon svgIcon="menu" (click)="sidenav.toggle()"></mat-icon>
        </span> -->
      </div>

      <mat-menu class="legend-menu" #legends="matMenu" xPosition="after" yPosition="below">
        <mat-list class="legend-list" *ngIf="pageTitle == 'Appointment Management'">
          <mat-list-item class="legend-color">
            <li><span class="confirm"></span> Confirmed</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li><span class="pending"></span> Approval Pending</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="reject"></span> Rejected</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="past"></span> Past</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="open"></span> Open Hours</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="break"></span> Closed Hours</li>
          </mat-list-item>
        </mat-list>
        <mat-list class="legend-list" *ngIf="pageTitle == 'Dashboard'">
          <mat-list-item class="legend-color">
            <li><span class="break"></span> Total</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li><span class="future-bg"></span> Future</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="confirm-bg"></span> Approved</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="pending-bg"></span> Pending</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="reject-bg"></span> Stylist Rejected</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="stylistCancel-bg"></span> Stylist Canceled</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="cancel-bg"></span> Customer Canceled</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="past-bg"></span> Past</li>
          </mat-list-item>
        </mat-list>
        <mat-list class="legend-list" *ngIf="pageTitle == 'Stylist Management'">
          <mat-list-item class="legend-color">
            <li><span class="active"></span>Active</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li><span class="confirm"></span> Waiting for Approval</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li><span class="pending"></span> Under Progress</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="past"></span> Rejected</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="reject"></span>Inactive</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="delete"></span>Deleted</li>
          </mat-list-item>
        </mat-list>
        <mat-list class="legend-list"
          *ngIf="pageTitle == 'View Customer'  || pageTitle == 'Book Appointment' || pageTitle == 'Edit Appointment'">
          <mat-list-item class="legend-color">
            <li><span class="confirm"></span> Confirmed</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li><span class="pending"></span> Approval Pending</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="reject"></span> Rejected</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="past"></span> Past</li>
          </mat-list-item>
        </mat-list>
        <mat-list class="legend-list" *ngIf="pageTitle == 'Partner Management' || pageTitle == 'Staff'">
          <mat-list-item class="legend-color">
            <li><span class="active"></span>Active</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li><span class="pending"></span> Approval Pending</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="reject"></span>Inactive</li>
          </mat-list-item>
        </mat-list>
        <mat-list class="legend-list"
          *ngIf="pageTitle == 'Location Management'|| pageTitle == 'Service Categories' || pageTitle == 'Service' || pageTitle == 'Staff Roles' || pageTitle == 'Stylist Services' || pageTitle == 'Plans'|| pageTitle == 'Features'|| pageTitle == 'Offers' || pageTitle == 'SMS Reports'">
          <mat-list-item class="legend-color">
            <li><span class="active"></span>Active</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="reject"></span>Inactive</li>
          </mat-list-item>
        </mat-list>
      </mat-menu>
      <div class="notify-icons">
        <span class="legend-icon"
          *ngIf="pageTitle == 'Appointment Management' || pageTitle == 'Stylist Management' || pageTitle == 'Dashboard' ||
        pageTitle == 'View Customer' || pageTitle == 'Partner Management' || pageTitle == 'Staff' || pageTitle == 'Location Management'|| 
        pageTitle == 'Service Categories' || pageTitle == 'Service' || pageTitle == 'Staff Roles' || pageTitle == 'Stylist Services' || pageTitle == 'Book Appointment' || pageTitle == 'Edit Appointment'|| pageTitle == 'Plans'|| pageTitle == 'Features'|| pageTitle == 'Offers'">
          <mat-icon svgIcon="info-round" [matMenuTriggerFor]="legends" (click)="showLegend = !showLegend"></mat-icon>
        </span>
        <!-- <span class="menu-icon">
          <mat-icon svgIcon="menu" (click)="sidenav.toggle()"></mat-icon>
        </span> -->
        <img *ngIf="!imgPic" class="main-profile" src="/assets/images/avatar.svg" [matMenuTriggerFor]="Profile"
          width="30px" height="30px" alt="">
        <img *ngIf="imgPic" class="main-profile" [src]="profileImage" [matMenuTriggerFor]="Profile" width="30px"
          height="30px" alt="">
        <mat-menu #Profile="matMenu" class="profile-menu">
          <button mat-menu-item (click)="viewProfile()">Profile</button>
          <button *ngIf="typeId == 4&&subscribe == true" mat-menu-item (click)="planNav()">My Plan</button>
          <button *ngIf="typeId == 4&&subscribe == true" mat-menu-item (click)="billigNav()">Billing</button>
          <button *ngIf="this.typeId == 4" mat-menu-item (click)="viewPlan()">{{ subscribe ? 'Upgrade Plan' : 'Subscribe Plan' }}</button>
          <button mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>
      </div>
    </div>
  </header>
  <mat-sidenav-container class="menu-side-nav" autosize>

    <!-- <mat-sidenav #sidenav1 class="menu-list-small" mode="side" position="start" opened *ngIf="!sidenav.opened">
      <mat-nav-list *ngFor="let item of listMenu">
        <a class="icon-align" (click)="iconClick(item)"><mat-icon class="routeIcon" [svgIcon]="item.iconName"
            [title]="item.displayName"></mat-icon></a>
      </mat-nav-list>
    </mat-sidenav> -->

    <mat-sidenav #sidenav class="menu-list" mode="side" position="start" opened (mouseenter)="mouseenter()"
      (mouseleave)="mouseleave()">
      <mat-nav-list>
        <app-menu-list-item *ngFor="let item of accessList" [item]="item" [toggleListener$]="sideNavToggleValue$" (sideMenuClicked$)="closeClick($event)"></app-menu-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <div class="body-container">
      <div *ngIf="hideTitle">
        <!-- <mat-icon svgIcon="arrow-back" class="back-icon" style="cursor: pointer;" (click)="back()"></mat-icon> -->
        <h3 class="bread-crumb"><a (click)="breadcrumbClick(pageTitle)">{{pageTitle}}</a></h3>
      </div>
      <router-outlet></router-outlet>
    </div>
    <!-- Toggle icon -->
    <div class="toggle-icon" (click)="sidenavClick()"
      [ngClass]="{ 'icon-open': isExpanded, '': !isExpanded }">
      <mat-icon [svgIcon]="isExpanded ? 'arrow-light-right' : 'arrow-light-left'"></mat-icon>
    </div>
  </mat-sidenav-container>

</div>