import { Injectable } from '@angular/core';

@Injectable
  ({
    providedIn: 'root'
  })
export class Permission {
    
  private _permissions: Array<any> = [];

  hasPermissions() {
    const hasAccess = (this._permissions && this._permissions.length > 0) ? true : false;
    return hasAccess;
  }


  getPermissions() {

    return this._permissions;
  }


  setPermissions(data) {
    this._permissions = data;
  }
  clearPermission(){
    this._permissions = [];
  }
}