<a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}"
  (click)="item.children && item.children.length && isSideNavOpened?openMenu($event):onItemSelected($event,item)"
  [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
  class="menu-list-item">
  <a *ngIf="item.children && item.children.length && isSideNavOpened"> <mat-icon class="routeIcon"
      [svgIcon]="item.iconName" [title]="item.displayName" [matMenuTriggerFor]="menu"></mat-icon>

    <mat-menu #menu="matMenu" class="sub-menu">
      <button mat-menu-item *ngFor="let child of item.children" (click)="onItemSelected($event,child)">
        <mat-icon [svgIcon]="child.iconName"></mat-icon>
        <span>{{child.displayName}}</span>
      </button>
    </mat-menu>
  </a>
  <a *ngIf="!isSideNavOpened ||!item.children"> <mat-icon class="routeIcon" [svgIcon]="item.iconName"
      [title]="item.displayName"></mat-icon>
  </a>
  <!-- <ng-container> -->

  <span class="menu-name" [title]="item.displayName" *ngIf="!isSideNavOpened"> {{item.displayName}}</span>
  <!-- </ng-container> -->

  <span fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <mat-icon class="menu-button" *ngIf="!isSideNavOpened" #arrow
      [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" svgIcon="arrow-down">

    </mat-icon>
  </span>
</a>
<div *ngIf="expanded">
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
  </app-menu-list-item>
</div>