import { Profile, MappingPair } from '@dynamic-mapper/mapper';
import { UniversityDTO } from 'src/app/domain/sample/university.dto';
import { University } from '../../models/sample/university.model';

export class SampleProfile extends Profile {
    static readonly DtoToDomain = new MappingPair<University,UniversityDTO>();

    constructor() {
        super();

        this.createMap(SampleProfile.DtoToDomain, {
            country: opt => opt.mapFrom(src => src.country),
            countryCode: opt => opt.mapFrom(src => src.alpha_two_code),
            name: opt => opt.mapFrom(src => src.name),
            siteUrl: opt => opt.mapFrom(src => src.web_pages)
        });
    }
}
