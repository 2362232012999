import { NgModule } from "@angular/core";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { Icons } from "./core/store/icons/icon-list";
import { DomSanitizer } from "@angular/platform-browser";

@NgModule({
  imports: [MatIconModule],
  exports: [
    MatIconModule
  ],
  providers: [MatIconRegistry]
})
export class IconModule {
  customIcons = Icons;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer){
   this.customIcons.forEach((e:any)  => {
    iconRegistry.addSvgIcon(e.iconName,
      sanitizer.bypassSecurityTrustResourceUrl(e.iconPath))
   });
  }
}
