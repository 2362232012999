import { Injectable } from '@angular/core';
import { AuthBusinessService } from 'src/app/core/business/auth/auth-business.service';

@Injectable({
  providedIn: 'root'
})
export class AuthFacadeService {

  constructor(private authBusiness: AuthBusinessService) { }

  forgotPassword(email: string): Promise<string> {
    return this.authBusiness.forgotPassword(email);
  }

  resetPassword(code: any, password: any) {
    return this.authBusiness.resetPassword(code, password);
  }

  signIn(email:string, password:string){
    return this.authBusiness.signIn(email,password);
  }

  signUp(email:string, password:string){
    return this.authBusiness.signUp(email, password);
  }

  googleLogin(loginFrom:any){
    return this.authBusiness.googleLogin(loginFrom);
  }

  faceBookLogin(loginFrom:any){
    return this.authBusiness.faceBookLogin(loginFrom);
  }

  appleLogin(loginFrom:any){
    return this.authBusiness.appleLogin(loginFrom);
  }

  updateEmail(email:string){
    return this.authBusiness.updateEmail(email);
  }

  deleteUser(){
    return this.authBusiness.deleteUser();
  }

  signOut(){
    return this.authBusiness.signOut();
  }
  
  uploadProfileImage(filePath:  string,file: any){
   return this.authBusiness.uploadProfileImage(filePath, file);
  }
}
