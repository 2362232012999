import { MappingPair, Profile } from "@dynamic-mapper/mapper";
import { OpenHoursDTO } from "src/app/domain/calendar/open-hour.dto";
import { DailySchedule } from "../../models/settings/daily-schedule.model";

export class OpenHours extends Profile {
  static readonly DtoToDomain = new MappingPair<DailySchedule,OpenHoursDTO>();

  constructor() {
    super();

    this.createMap(OpenHours.DtoToDomain, {
      id: opt => opt.mapFrom(src => src.id),
      stylistId: opt => opt.mapFrom(src => src.stylistId),
      date: opt => opt.mapFrom(src => src.date),
      startTime: opt => opt.mapFrom(src => src.startTime),
      endTime: opt => opt.mapFrom(src => src.endTime)
    })
  }
}
