import { Profile, MappingPair } from '@dynamic-mapper/mapper';
import { CustomerDTO } from 'src/app/domain/customer/customer.dto';
import { Customer } from '../../models/customer/customer.model';

export class CustomerProfile extends Profile {
    static readonly DtoToDomain = new MappingPair<Customer,CustomerDTO>();

    constructor(){
        super();
        
        this.createMap(CustomerProfile.DtoToDomain,{
            pageNumber:opt => opt.mapFrom(src => src.pageNumber),
            pageSize:opt => opt.mapFrom(src => src.pageSize),
            totalItems:opt  => opt.mapFrom(src => src.totalItems),
            list:opt => opt.mapFrom(src => src.list),
            searchTerm:opt => opt.mapFrom(src => src.searchTerm)
        })
    }
}