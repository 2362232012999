<div class="main-logout-container" *ngIf="value == 'logout' ">
    <h2 class="logout-header">Logout?</h2>
    <hr>
    <p class="logout-title">Are you sure you want to logout?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="logout-button" mat-flat-button color="primary" (click)="logout()">Yes, Logout</button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'singleApprove' ">
    <h2 class="logout-header">Approve?</h2>
    <hr>
    <p class="logout-title">Are you sure to approve this onboarding request?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="logout-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="singleApprove()">Yes, Approve</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'singleReject' ">
    <h2 class="logout-header">Reject?</h2>
    <hr>
    <p class="logout-title">Are you sure to reject this onboarding request?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="logout-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="singleReject()">Yes, Reject</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'allApprove' ">
    <h2 class="logout-header">Approve?</h2>
    <hr>
    <p class="logout-title">Are you sure to approve all onboarding requests?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="logout-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="approveAll()">Yes, Approve</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'allReject' ">
    <h2 class="logout-header">Reject ?</h2>
    <hr>
    <p class="logout-title">Are you sure to reject all onboarding requests?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="logout-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="rejectAll()">Yes, Reject</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'updateRole' ">
    <h2 class="logout-header">Update Role</h2>
    <hr>
    <p class="logout-title line-height">This staff role is created by a partner.<br>Are you sure want to change the
        partner?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="staffrole-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary"
            (click)="updateRole()">Yes</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div>
</div>

<div class="main-logout-container" *ngIf="value == 'generateUrl' ">
    <h2 class="url-header">Stylist Onboarding Link</h2>
    <hr>
    <div class="url-copy-container">
        <span>{{ url }}</span>
        <mat-icon class="copy-icon" title="Copy" (click)="copyUrl()" svgIcon="copy"></mat-icon>
    </div>
    <!-- <div class="logout-button-div">
        <button class="logout-cancel-button" mat-flat-button color="accent" (click)="close()">Cancel</button>
        <button class="staffrole-button" mat-flat-button *ngIf="!userFeedback.loading" color="primary">Yes</button>
        <button class="logout-button" mat-flat-button *ngIf="userFeedback.loading" color="primary">
            <mat-icon class="button-load" svgIcon="loader"></mat-icon>
        </button>
    </div> -->
</div>