import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmOnboardService {

  private onboardUpdate = new Subject<void>()

  updateOnboard() {
    this.onboardUpdate.next();
  }

  getUpdateObservable() {
    return this.onboardUpdate.asObservable();
  }
}
