import { Injectable } from '@angular/core';
import { StaffService } from '../../services/staff/staff.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaffBusinessService {

  constructor(private staffService: StaffService) { }

  getRoleList(paramsReq): Observable<any> {
    return new Observable((observe) => {
      this.staffService.getRoleList(paramsReq).subscribe({
        next: (response) => {
          try {
            observe.next(response)
          }
          catch (e) {
            observe.error(e);
          }
        },
        error: (error) => {
          observe.error(error);
        }
      })
    })
  }

  getStaffPaginationList(paramsReq): Observable<any> {
    return new Observable((observe) => {
      this.staffService.getStaffPaginationList(paramsReq).subscribe({
        next: (response) => {
          try {
            observe.next(response)
          }
          catch (e) {
            observe.error(e);
          }
        },
        error: (error) => {
          observe.error(error);
        }
      })
    })
  }

  resendEmail(data): Observable<any> {
    return new Observable((observe) => {
      this.staffService.resendEmail(data).subscribe({
        next: (response) => {
          try {
            observe.next(response)
          }
          catch (e) {
            observe.error(e);
          }
        },
        error: (error) => {
          observe.error(error);
        }
      })
    })
  }
  createStaff(data): Observable<any> {
    return new Observable((observe) => {
      this.staffService.createStaff(data).subscribe({
        next: (response) => {
          try {
            observe.next(response)
          }
          catch (e) {
            observe.error(e);
          }
        },
        error: (error) => {
          observe.error(error);
        }
      })
    })
  }

  updateStaff(data): Observable<any> {
    return new Observable((observe) => {
      this.staffService.updateStaff(data).subscribe({
        next: (response) => {
          try {
            observe.next(response)
          }
          catch (e) {
            observe.error(e);
          }
        },
        error: (error) => {
          observe.error(error);
        }
      })
    })
  }

  getRolePaginationList(paramsReq): Observable<any> {
      return new Observable((observe) => {
        this.staffService.getRolePaginationList(paramsReq).subscribe({
          next: (response) => {
            try {
              observe.next(response)
            }
            catch (e) {
              observe.error(e);
            }
          },
          error: (error) => {
            observe.error(error);
          }
        })
      })
    }

    getRoleAccess(id): Observable<any> {
      return new Observable((observe) => {
        this.staffService.getRoleAccess(id).subscribe({
          next: (response) => {
            try {
              observe.next(response)
            }
            catch (e) {
              observe.error(e);
            }
          },
          error: (error) => {
            observe.error(error);
          }
        })
      })
    }

    getAccessScreen(id): Observable<any> {
      return new Observable((observe) => {
        this.staffService.getAccessScreen(id).subscribe({
          next: (response) => {
            try {
              observe.next(response)
            }
            catch (e) {
              observe.error(e);
            }
          },
          error: (error) => {
            observe.error(error);
          }
        })
      })
    }


    createRole(data):Observable<any>{ 
      return new Observable((observe) => {
        this.staffService.createRole(data).subscribe({
          next: (response) => {
            try {
              observe.next(response)
            }
            catch (e) {
              observe.error(e);
            }
          },
          error: (error) => {
            observe.error(error);
          }
        })
      })
    }
  
    updateRole(data):Observable<any>{ 
      return new Observable((observe) => {
        this.staffService.updateRole(data).subscribe({
          next: (response) => {
            try {
              observe.next(response)
            }
            catch (e) {
              observe.error(e);
            }
          },
          error: (error) => {
            observe.error(error);
          }
        })
      })
    }
}
