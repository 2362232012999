import { Profile, MappingPair } from '@dynamic-mapper/mapper';
import { ServicePageDTO } from 'src/app/domain/service/service-page.dto';
import { ServicePage } from '../../models/service/service-page.modal';

export class ServicePageListData extends Profile {
    static readonly DtoToDomain = new MappingPair<ServicePage, ServicePageDTO>();
    constructor() {
        super();

        this.createMap(ServicePageListData.DtoToDomain, {
            totalItems: opt => opt.mapFrom(src => src.totalItems),
            pageNumber: opt => opt.mapFrom(src => src.pageNumber),
            pageSize: opt => opt.mapFrom(src => src.pageSize),
            list: opt => opt.mapFrom(src => src.list),
            searchTerm: opt => opt.mapFrom(src => src.searchTerm),
        });
    }
}