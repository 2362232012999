import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatBottomSheet,
  MatBottomSheetConfig,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import { MatMenuTrigger } from "@angular/material/menu";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, Observable, filter, map, mergeMap } from "rxjs";
import { UserFacadeService } from "src/app/facades/user/user-facade.service";
import { LogoutComponent } from "../../auth-container/logout/logout.component";
import { SharedService } from "src/app/core/services/shared/shared.service";
import { MatSidenav } from "@angular/material/sidenav";
import { NavService } from "src/app/core/utilities/navigation/nav.service";
// import { MenuList } from "src/app/core/store/permission/menu-list";
import { Location } from "@angular/common";
import { CommonHelperService } from "src/app/core/utilities/common-helper/common-helper.service";
import { UserFeedbackState } from "src/app/core/utilities/user-feedback-state/user-feedback-state";
import { SubscriptionFacadesService } from "src/app/facades/subscription/subscription-facades.service";
@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MainLayoutComponent implements OnInit {
  pageTitle!: string;
  userId: number;
  profileImage: string = "";
  imgPic: boolean = false;
  showLegend: boolean = false;
  changeIndex: number;

  @ViewChild("sidenav", { static: false }) sidenav: MatSidenav;
  @ViewChild("sidenav1") sidenav1: ElementRef;
  sidenavSmall: boolean = false;
  showServiceMenu: boolean = false;
  darkMode: boolean;
  isServiceShowing = false;
  isSettingsShowing = false;
  isServiceExpanded = true;
  isSettingsExpanded = true;
  showSettingsMenu: boolean = false;
  // accessList = MenuList;
  accessList = [];
  // Use MenuList array directly bcz screen data not correctly comes from the menu-list.ts
  listMenu = [
    {
      displayName: "Dashboard",
      iconName: "dashboard",
      route: "/dashboard",
    },
    {
      displayName: "Partner Management",
      iconName: "profile-light",
      route: "/partners",
    },
    {
      displayName: "Location Management",
      iconName: "location",
      route: "/locations",
    },
    {
      displayName: "Onboarding Approval",
      iconName: "onboard",
      route: "/onboarding",
    },
    {
      displayName: "Stylist Management",
      iconName: "users",
      route: "/stylists",
    },
    {
      displayName: "Service Management",
      iconName: "work-light",
      route: "/services",
      children: [
        {
          displayName: "Service Categories",
          iconName: "work-light",
          route: "/services/service-category",
        },
        {
          displayName: "Service",
          iconName: "work-light",
          route: "/services/service-name",
        },
        {
          displayName: "Stylist's Services",
          iconName: "work-light",
          route: "/services/service",
        },
      ],
    },
    {
      displayName: "Subscription Management",
      iconName: "bookmark",
      route: "/subscription",
      children: [
        {
          displayName: "Plans",
          iconName: "graph-light",
          route: "/subscription/plans",
        },
        {
          displayName: "Features",
          iconName: "star-light",
          route: "/subscription/features",
        },
        {
          displayName: "Offers",
          iconName: "discount-light",
          route: "/services/offers",
        },
      ],
    },
    {
      displayName: "Appointment Management",
      iconName: "appointment",
      route: "/appointments",
    },
    {
      displayName: "Customer Management",
      iconName: "customers",
      route: "/customers",
    },
    {
      displayName: "Settings",
      iconName: "setting",
      route: "/settings",
      children: [
        {
          displayName: "Profile",
          iconName: "profile-light",
          route: "/settings",
        },
        {
          displayName: "Staff",
          iconName: "users",
          route: "/settings/staff",
        },
        {
          displayName: "Staff Roles",
          iconName: "onboard",
          route: "/settings/staff-role",
        },
      ],
    },
  ];
  hideTitle: boolean;
  userFeedback = new UserFeedbackState();
  @ViewChild(MatMenuTrigger) legends: MatMenuTrigger;
  authId: string;
  typeId: any;
  subscribe: any;
  alwaysOpen = true;
  private sideNavToggleValue = new BehaviorSubject<any>({});
  sideNavToggleValue$: Observable<any> = this.sideNavToggleValue.asObservable();
  isExpanded: boolean = true;
  subscriptionMapped: any;
  clientId: string;
  intervalId: NodeJS.Timer;

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userFacadeService: UserFacadeService,
    private bottom: MatBottomSheet,
    private sharedService: SharedService,
    private commonHelper: CommonHelperService,
    private navService: NavService,
    private subscriptionFacade: SubscriptionFacadesService,
    private renderer: Renderer2
  ) {
    this.darkMode = this.commonHelper.isDarkEnabled();
    this.setMenuByRoleAccess();
    this.authId = localStorage.getItem("authId");
    this.typeId = localStorage.getItem("typeId");
    this.subscriptionMapped = localStorage.getItem("subscriptionMapped");
    if (this.typeId == 2) {
      this.userId = Number(localStorage.getItem("staffId"));
    } else {
      this.userId = Number(localStorage.getItem("clientId"));
      this.getFeatureAccess();
      // Call the API every 20 minutes
      this.intervalId = setInterval(() => {
      this.getFeatureAccess();
      }, 1200000); // 20 minutes in milliseconds
    }
    this.setPageTitle();
    this.getUserDetails();
  }
  ngAfterViewInit() {
    this.navService.sidenav = this.sidenav;
  }

  sidenavClick() {
    this.isExpanded = !this.isExpanded;
    this.sideNavToggleValue.next(this.isExpanded);
  }
  mouseenter() {
    if (!this.isServiceExpanded) {
      this.isServiceShowing = true;
    }
    if (!this.isSettingsExpanded) {
      this.isSettingsShowing = true;
    }
  }

  mouseleave() {
    if (!this.isServiceExpanded) {
      this.isServiceShowing = false;
    }
    if (!this.isSettingsExpanded) {
      this.isSettingsShowing = false;
    }
  }
  getUserDetails() {
    try {
      let obj = {
        AuthId: this.authId,
        UserTypeId: "",
      };
      this.userFacadeService.getUserByAuth(obj).subscribe({
        next: (response: any) => {
          this.profileImage = response.images;
          this.subscribe = response.isSubscriptionMapped;
          localStorage.setItem("locationId", response.locationId);
          localStorage.setItem("subscriptionMapped", response.isSubscriptionMapped);
          if (this.profileImage == "" || this.profileImage == null) {
            this.imgPic = false;
          } else {
            this.imgPic = true;
          }
        },
        error: (error) => {
          this.imgPic = false;
        },
      });
    } catch (error: any) {
      this.imgPic = false;
    }
  }

  getFeatureAccess() {
    this.userFeedback.startLoading();
    try {
      let data = {
        clientId: this.userId,
      };
      this.subscriptionFacade.getFeatureAccess(data).subscribe({
        next: (res: any) => {
          localStorage.setItem("isCalendarAccess", res.isCalendarAccess);
          localStorage.setItem("isDoubleBookingAccess", res.isDoubleBookingAccess);
          localStorage.setItem("isRecurringAccess", res.isRecurringAccess);
          localStorage.setItem("isReportsAccess", res.isReportsAccess);
          //localStorage.setItem('locationLimitReached', res.locationLimitReached);
          localStorage.setItem("notesAccess", res.notesAccess);
          localStorage.setItem("onlineBookAccess", res.onlineBookAccess);
        },
        error: (error) => {
          //this.showError(error);
          this.userFeedback.stopLoading();
        },
      });
    } catch (error) {
      //this.showError(error);
      this.userFeedback.stopLoading();
    }
  }

  setPageTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let parentRoute = this.activatedRoute.firstChild;
          let route = this.activatedRoute.firstChild?.firstChild;
          parentRoute.data.subscribe((dat) => {
            const parentPage = dat["pageTitle"];
            route.data.subscribe((d) => {
              if (parentPage == d["pageTitle"]) {
                this.hideTitle = true;
              } else if (
                d["pageTitle"] == "Service Categories" ||
                d["pageTitle"] == "Service" ||
                d["pageTitle"] == "Stylist Services" ||
                d["pageTitle"] == "Staff" ||
                d["pageTitle"] == "Staff Roles"
              ) {
                this.hideTitle = true;
              } else if (
                d["pageTitle"] == "Plans" ||
                d["pageTitle"] == "Features" ||
                d["pageTitle"] == "Offers"
              ) {
                this.hideTitle = true;
              } else {
                this.hideTitle = false;
              }
            });
          });
          if (route?.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route: any) => route.data)
      )
      .subscribe((data: any) => {
        let findIndex: any = this.activatedRoute.routeConfig?.children;
        this.pageTitle = data.pageTitle;
        findIndex.forEach((e: any, i: any) => {
          if (e.data?.pageTitle == this.pageTitle) {
            // this.breadcrumbClick(this.pageTitle)
          } else {
            return;
          }
        });
      });
  }
  ngOnInit(): void {
    this.sharedService.getUpdateObservable().subscribe(() => {
      this.getUserDetails();
    });
  }

  breadcrumbClick(pageTitle) {
    switch (pageTitle) {
      case "Dashboard":
        this.routeChange("/dashboard");
        break;
      case "Partner Management":
        this.routeChange("/partners");
        break;
      case "Location Management":
        this.routeChange("/locations");
        break;
      case "Onboarding Approval":
        this.routeChange("/onboarding");
        break;
      case "Stylist Management":
        this.routeChange("/stylists");
        break;
      case "Service Management":
        // this.pageTitle = 'Service Category';
        // this.router.navigate(['/services/service-category']);
        break;
      case "Service Categories":
        this.routeChange("/services/service-category");
        break;
      case "Service":
        this.routeChange("/services/service-name");
        break;
      case "Stylist Services":
        this.routeChange("/services/service");
        break;
      case "Subscription Management":
        break;
      case "Plans":
        this.routeChange("/subscription/plans");
        break;
      case "Features":
        this.routeChange("subscription/features");
        break;
      case "Offers":
        this.routeChange("/subscription/offers");
        break;
      case "Appointment Management":
        this.routeChange("/appointments");
        break;
      case "Customer Management":
        this.routeChange("/customers");
        break;
      case "Settings":
        // this.pageTitle = "Profile"
        // this.router.navigate(['/settings']);
        break;
      case "Profile":
        this.routeChange("/settings");
        break;
      case "Staff":
        this.routeChange("/settings/staff");
        break;
      case "Staff Roles":
        this.routeChange("/settings/staff-role");
        break;
    }
  }

  routeChange(url) {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }
  onTabClick(event) {
    // Get the label of the clicked tab.
    const clickedTabLabel = event.target.textContent.trim();
    if (clickedTabLabel != "") {
      this.breadcrumbClick(clickedTabLabel);
    }
  }
  logout() {
    const config: MatBottomSheetConfig = {
      hasBackdrop: true, // Enable backdrop
    };

    const bottomSheetRef: MatBottomSheetRef = this.bottom.open(
      LogoutComponent,
      {
        panelClass: "custom-style",
        data: {
          value: "logout",
        },
        ...config,
      }
    );

    bottomSheetRef.afterOpened().subscribe(() => {
      // Listen for backdrop click event and close the bottom sheet
      const backdrop = document.querySelector(".cdk-overlay-backdrop");
      if (backdrop) {
        backdrop.addEventListener("click", () => {
          bottomSheetRef.dismiss();
        });
      }
    });
  }
  //for navigating to edit profile
  viewProfile() {
    this.router.navigate(["/settings"]).then(() => window.location.reload());
  }
  selectMenu(event: any) {
    if (event.target.title) {
      this.pageTitle = event.target.title;
    }
    this.breadcrumbClick(this.pageTitle);
  }
  compareObjects(obj1: any, obj2: any): boolean {
    if (obj1.displayName != obj2.screenName) {
      return false;
    }
    return true; // Return true if objects are equal, false otherwise
  }

  // setMenuByRoleAccess() {
  //   try {
  //     this.userFacadeService.setMenuByRoleAccess().subscribe({
  //       next: (res) => {
  //         this.accessList.forEach((data,di) => {
  //           if(data.children && data.children.length>0){
  //            data.children.forEach((ce, ci) => {

  //             const cii = res[0]['accesss'].findIndex((item) => this.compareObjects(ce,item));
  //                if (cii === -1){
  //             this.accessList[di].children.splice(ci,1)
  //           }
  //         });
  //       }
  //           const foundObjectIndex = res[0]['accesss'].findIndex((item) => this.compareObjects(data, item));
  //           if (foundObjectIndex === -1) {
  //             this.accessList.splice(this.accessList.indexOf(data), 1);
  //           }
  //         });
  //       }, error(err) {

  //       },
  //     })
  //   } catch (error) {

  //   }
  // }

  setMenuByRoleAccess() {
    try {
      this.userFeedback.startLoading();
      this.accessList = this.listMenu;
      this.userFacadeService.setMenuByRoleAccess().subscribe({
        next: (res) => {
          // Check if all items in res have both read and write as false
          const allFalse = res[0]["accesss"].every(
            (item) => !item.read && !item.write
          );

          // If all items have both read and write as false, hide the side menu
          if (allFalse) {
            this.accessList = [];
            return;
          }

          // Filter out screens with both read and write false
          this.accessList = this.accessList.filter((data) => {
            // Check if current item exists in res array
            const foundObject = res[0]["accesss"].find((item) =>
              this.compareObjects(data, item)
            );
            if (!foundObject || (!foundObject.read && !foundObject.write)) {
              return false; // Remove the item from the list
            } else {
              // Check children if present
              if (data.children && data.children.length > 0) {
                data.children = data.children.filter((ce) => {
                  const childIndex = res[0]["accesss"].findIndex((item) =>
                    this.compareObjects(ce, item)
                  );
                  return (
                    childIndex !== -1 &&
                    (res[0]["accesss"][childIndex].read ||
                      res[0]["accesss"][childIndex].write)
                  );
                });
              }
              return true; // Keep the item in the list
            }
          });
          this.userFeedback.stopLoading();
        },
        error: (err) => {
          this.userFeedback.stopLoading();
        },
      });
    } catch (error) {
      this.userFeedback.stopLoading();
    }
  }

  back() {
    this.location.back();
  }

  iconClick(item) {
    this.listMenu.forEach((element) => {
      if (element.iconName == item.iconName) {
        if (item.displayName == "Service Management") {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/services/service"]);
            });
        } else {
          // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          //   this.router.navigate([element.route]);
          // });
          this.router
            .navigate([element.route])
            .then((success) => {})
            .catch((err) => {});
        }
      }
    });
  }

  closeClick(eve) {
    this.isExpanded = eve;
    this.sideNavToggleValue.next(eve);
  }

  planNav() {
    this.router.navigate(["/partner-subscription/plan-details"]);
  }

  billigNav() {
    this.router.navigate(["/partner-subscription/billing-details"]);
  }
}
