import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnboardingBusinessService } from 'src/app/core/business/onboarding/onboarding-business.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingFacadeService {

  constructor(private onboardBusiness: OnboardingBusinessService) { }

  getOnboardPagination(paramsReq: any):Observable<any> {
    return this.onboardBusiness.getOnboardPagination(paramsReq);
  }

  stylistApproveOrReject(data): Observable<any> {
    return this.onboardBusiness.stylistApproveOrReject(data);
  }
}
