import { Injectable } from '@angular/core';
import { OnboardingService } from '../../services/onboarding/onboarding.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnboardingBusinessService {

  constructor(private onboardService: OnboardingService) { }

  getOnboardPagination(paramsReq: any): Observable<any> {
    return new Observable((observer) => {
      this.onboardService.getOnboardPagination(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  stylistApproveOrReject(data): Observable<any> {
    return new Observable((observer) => {
      this.onboardService.stylistApproveOrReject(data).subscribe({
        next: (response) => {
          
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
}
