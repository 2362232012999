import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ThemeService } from './core/theme/theme.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mvm2';
  constructor(
    private router: Router,
    private themeService: ThemeService,
    private platform: Platform
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.themeService.initializeTheme();
      }
      window.scrollTo(0, 0);
    })
    if (this.platform.IOS) {
      document.body.style.setProperty('-webkit-overflow-scrolling', 'none');
    }
  }
  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    // Disable momentum scrolling
    event.preventDefault();
  }
}
