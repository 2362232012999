import { Profile, MappingPair } from '@dynamic-mapper/mapper';
import { CustomerDetails } from '../../models/customer/customer-details';
import { CustomerDetailsDTO} from 'src/app/domain/customer/customer-details.dto';

export class CustomerDetail extends Profile{
    static readonly DtoToDomain = new MappingPair<CustomerDetails,CustomerDetailsDTO>();

    constructor(){
        super();

        this.createMap(CustomerDetail.DtoToDomain,{
            userId:opt => opt.mapFrom(src => src.userId),
            firstName: opt => opt.mapFrom(src => src.firstName),
            lastName:opt => opt.mapFrom(src => src.lastName),
            email:opt => opt.mapFrom(src => src.email),
            phoneNumber:opt => opt.mapFrom(src => src.phoneNumber),
            smsNumber:opt => opt.mapFrom(src => src.smsNumber),
            images:opt => opt.mapFrom(src => src.images),
            lastVisit:opt => opt.mapFrom(src => src.lastVisit),
            userTypeId:opt => opt.mapFrom(src => src.userTypeId),
            formattedPhoneNumber:opt => opt.mapFrom(src => src.formattedPhoneNumber),
        })
    }
}