import { MappingPair, Profile } from "@dynamic-mapper/mapper";
import { CalendarEvents } from "../../models/calendar/calendar-events";
import { EventsDTO } from "src/app/domain/calendar/events.dto";

export class EventProfile extends Profile {
  static readonly DtoToDomain = new MappingPair<CalendarEvents, EventsDTO>();

  constructor() {
    super();

    this.createMap(EventProfile.DtoToDomain, {
      appointmentId: opt => opt.mapFrom(src => src.appointmentId),
      date: opt => opt.mapFrom(src => src.date),
      groupId: opt => opt.mapFrom(src => src.groupId),
      id: opt => opt.mapFrom(src => src.id),
      firstName: opt => opt.mapFrom(src => src.firstName),
      lastName: opt => opt.mapFrom(src => src.lastName),
      startTime: opt => opt.mapFrom(src => src.startTime),
      endTime: opt => opt.mapFrom(src => src.endTime),
      recurringTypeId: opt => opt.mapFrom(src => src.recurringTypeId),
      isApproved: opt => opt.mapFrom(src => src.isApproved),
      recordStatus: opt => opt.mapFrom(src => src.recordStatus),
      isPast: opt => opt.mapFrom(src => src.isPast),
      notes: opt => opt.mapFrom(src => src.notes),
      services: opt => opt.mapFrom(src => src.services)
    })
  }
}
