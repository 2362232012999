import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionBusinessService } from 'src/app/core/business/subscription/subscription-business.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionFacadesService {

  constructor(private subscriptionBusinessService: SubscriptionBusinessService) { }

  getFeaturePagination(data): Observable<any> {
    return this.subscriptionBusinessService.getFeaturePagination(data);
  }
  getPlanPagination(data): Observable<any> {
    return this.subscriptionBusinessService.getPlanPagination(data);
  }
  getFeatureById(paramsReq: object) {
    return this.subscriptionBusinessService.getFeatureById(paramsReq);
  }

  getPlanByCliId(paramsReq: object){
    return this.subscriptionBusinessService.getPlanByCliId(paramsReq);
  }
  getPlanById(paramsReq: object) {
    return this.subscriptionBusinessService.getPlanById(paramsReq);
  }
  getFeatureData(): Observable<any> {
    return this.subscriptionBusinessService.getFeatureData();
  }

  getFeatureNameList(): Observable<any> {
    return this.subscriptionBusinessService.getFeatureNameList();
  }
  getPlanList():Observable<any>{
    return this.subscriptionBusinessService.getPlanList();
  }

  getPricingList():Observable<any>{
    return this.subscriptionBusinessService.getPricingList();
  }

  updateFeature(data):Observable<any>{
    return this.subscriptionBusinessService.updateFeature(data)
  }

  updateClientwithPlan(data):Observable<any>{
    return this.subscriptionBusinessService.updateClientwithPlan(data)
  }

  createAddFeature(data: any): Observable<any> {
    return this.subscriptionBusinessService.createAddFeature(data);
  }

  getPlanNameByCountry(obj):Observable<any>{
    return this.subscriptionBusinessService.getPlanNameByCountry(obj);
  }
  updatePlan(data): Observable<any> {
    return this.subscriptionBusinessService.updatePlan(data)
  }
  createPlan(data: any): Observable<any> {
    return this.subscriptionBusinessService.createPlan(data);
  }
  getBillingPagination(data): Observable<any> {
    return this.subscriptionBusinessService.getBillingPagination(data);
  }
  getPartPlanInfo(data): Observable<any> {
    return this.subscriptionBusinessService.getPartPlanInfo(data);
  }
  getPlanFeature(data): Observable<any> {
    return this.subscriptionBusinessService.getPlanFeature(data);
  }
  getFeatureAccess(data): Observable<any> {
    return this.subscriptionBusinessService.getFeatureAccess(data);
  }
}
