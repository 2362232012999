import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavService } from 'src/app/core/utilities/navigation/nav.service';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: any;
  @Input() depth: number;
  @Input() toggleListener$;
  isSideNavOpened: boolean = false;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild('menuTrigger') menuTriggerRef: ElementRef;
  // @Output() sideNavClicked:boolean;
  private sideMenuClicked = new BehaviorSubject<any>({});
  @Output() sideMenuClicked$: Observable<any> = this.sideMenuClicked.asObservable();
  constructor(public navService: NavService,
    public router: Router,
    private cd: ChangeDetectorRef) {
    if (this.depth === undefined) {
      this.depth = 0;
    }

  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
    if (this.toggleListener$) {
      this.toggleListener$.subscribe(evt => {
        this.isSideNavOpened = evt;
        if(this.isSideNavOpened){
          this.expanded = false;
        }
      })
    }
  }

  onItemSelected(ev, item: any) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]).then(success => {
        this.sideMenuClicked.next(true);
      }).catch(err => {
      });
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;   
      this.sideMenuClicked.next(false);

      this.cd.detectChanges();
    }
  }


  openMenu(event) {
    event.stopPropagation();  // Prevents event bubbling
    this.menuTrigger.openMenu();
  }

}
